import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import type { NavItem } from "."

const MobileNav: React.FC<{
  items: Array<NavItem>
  activeItem: NavItem
}> = ({ items, activeItem }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <ActiveItem onClick={() => setIsOpen(!isOpen)}>
        <Label>{activeItem?.label}</Label>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </Icon>
      </ActiveItem>
      {isOpen && (
        <List>
          {items.map(item => (
            <ListItem key={item.label}>
              {item.type === "internal" ? (
                <Link to={item.url} activeClassName="active">
                  {item.label}
                </Link>
              ) : (
                <a href={item.url}>{item.label}</a>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100%;

  @media (min-width: 1120px) {
    display: none;
  }
`

const ActiveItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: #fff;
  width: 100%;
  height: 100%;
`

const Label = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${p => p.theme.colors.text.base};
  text-transform: uppercase;
  margin-right: 5px;
  letter-spacing: 0.04em;
`

const Icon = styled.span`
  color: ${p => p.theme.colors.text.base};
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 22px;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  background: white;
  overflow: hidden;
  box-shadow: 0 5px 8px -5px #00000021;
  top: 100%;
`

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  border-top: 1px solid #ececec;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${p => p.theme.colors.text.base};
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
`

export default MobileNav
