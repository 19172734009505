import React, { useMemo } from "react"
import styled from "styled-components"
import DesktopNav from "./Desktop"
import MobileNav from "./Mobile"

export type NavItem = {
  label: string
  url: string
  type: "internal" | "external"
}

type SecondaryNav = {
  items: Array<NavItem>
  currentPath: string
}

const SecondaryNav: React.FC<SecondaryNav> = ({ items, currentPath }) => {
  const activeItem = useMemo(() => {
    const activeItem = items.find(item => item.url === currentPath)
    return activeItem || items?.[0]
  }, [items, currentPath])

  return (
    <Nav aria-label="Secondary">
      <DesktopNav activeItem={activeItem} items={items} />
      <MobileNav activeItem={activeItem} items={items} />
    </Nav>
  )
}

const Nav = styled.nav`
  background: #fff;
  box-shadow: 0 0 4px 0 #00000021;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
  z-index: 1;
  height: 64px;
`

export default SecondaryNav
