import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BoxedContainer from "../../elements/BoxedContainer"
import { buttonRoundStyle } from "../../elements/Buttons"
import type { NavItem } from "."

const DesktopNav: React.FC<{ items: NavItem[]; activeItem: NavItem }> = ({
  items,
  activeItem,
}) => (
  <Container>
    <List>
      {items.map(item => (
        <ListItem key={item.label}>
          {item.type === "internal" ? (
            <InternalLink to={item.url} $active={item.url === activeItem.url}>
              {item.label}
            </InternalLink>
          ) : (
            <ExternalLink href={item.url}>{item.label}</ExternalLink>
          )}
        </ListItem>
      ))}
    </List>
  </Container>
)

const Container = styled(BoxedContainer)`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (min-width: 1120px) {
    display: flex;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`

const ListItem = styled.li`
  margin: 0 7px;
  padding: 0;
`

const InternalLink = styled(Link)`
  ${buttonRoundStyle}
`

const ExternalLink = styled.a`
  ${buttonRoundStyle}
`

export default DesktopNav
