import React, { useMemo } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import MetaData from "../components/meta"
import Layout from "../components/Layout"
import SecondaryNav from "../components/SecondaryNav"
import { BoxedContainer, Text, Grid } from "../elements"
import ResourceCard from "../components/ResourceCard"

const PastEventsTemplate = ({ data, location, pageContext }) => {
  const { webinars, meetups } = data

  const allEvents = useMemo(() => {
    return [...webinars.edges, ...meetups.edges]
      .sort((a, b) => {
        return (
          new Date(b.node.published_at).getTime() -
          new Date(a.node.published_at).getTime()
        )
      })
      .slice(0, 6)
  }, [webinars.edges, meetups.edges])

  const renderedEvents = useMemo(() => {
    switch (pageContext.slug) {
      case "webinar":
        return webinars.edges
      case "meetup":
        return meetups.edges
      default:
        return allEvents
    }
  }, [pageContext.slug])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />
      <SecondaryNav
        items={[
          {
            label: "All Events",
            url: "/events/",
            type: "internal",
          },
          {
            label: "Webinars",
            url: "/events/webinar/",
            type: "internal",
          },
          {
            label: "Meetups",
            url: "/events/meetup/",
            type: "internal",
          },
        ]}
        currentPath={
          pageContext.slug === "webinar" || pageContext.slug === "meetup"
            ? `/events/${pageContext.slug}/`
            : `/events/`
        }
      />
      <Hero>
        <HeroInner>
          <StaticImage
            src="../images/events/past-events/calendar-icon.webp"
            alt="Calendar Icon"
            width={130}
            height={130}
            quality={75}
            layout="fixed"
            style={{
              marginBottom: "2rem",
            }}
          />
          <Text variant="uppercase" as="div" style={{ marginBottom: "1.6rem" }}>
            New Events Are Coming Soon
          </Text>
          <Title>Explore Past Events</Title>
        </HeroInner>
      </Hero>
      <BoxedContainer>
        <Grid
          gridTemplateColumns={[
            "1fr",
            "repeat(2, 1fr)",
            null,
            "repeat(3, 1fr)",
          ]}
          gridGap={["40px", "40px 30px", null, "60px 30px"]}
          justifyItems="center"
          pb="80px"
        >
          {renderedEvents.map(({ node }) => (
            <ResourceCard data={node} key={node.id} />
          ))}
        </Grid>
      </BoxedContainer>
    </Layout>
  )
}

const Hero = styled.section`
  padding: 80px 0;
  background-image: linear-gradient(to bottom, #f4f5f6, #fff);
`

const HeroInner = styled(BoxedContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: 700;
  margin: 0;
`

export default PastEventsTemplate

export const pageQuery = graphql`
  query PastEventsTemplateQuery {
    currentPage: ghostPage(slug: { eq: "events" }) {
      ...PageMetaFields
    }

    webinars: allGhostPost(
      filter: {
        primary_tag: { slug: { eq: "resources" } }
        tags: { elemMatch: { slug: { eq: "webinar" } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 6
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }

    meetups: allGhostPost(
      filter: {
        primary_tag: { slug: { eq: "resources" } }
        tags: { elemMatch: { slug: { eq: "online-meetup" } } }
      }
      sort: { order: DESC, fields: [published_at] }
      limit: 6
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
